import React from 'react';
import styled from 'styled-components';
import screens from '../@style/screens';
import SEO from '../components/seo';
import Layout from '../components/ui/layout';

const TermsWrapper = styled.div`
  padding: 2rem 0 6rem 0;
  @media ${screens.tablet} {
    padding: 3rem;
  }
  text-align: justify;
  p {
    padding: 10px 0;
  }
  li {
    padding: 5px 0;
  }
  ol {
    padding-left: 1rem;
    @media ${screens.tablet} {
      padding-left: 3rem;
    }
  }
  ol {
    list-style-type: upper-roman;
    li span:first-child {
      text-decoration: underline;
    }
    li span:last-child {
      text-decoration: none;
    }
    ol {
      list-style-type: upper-alpha;
      text-decoration: none;
      ol {
        list-style-type: decimal;
        ol {
          list-style-type: lower-alpha;
          ol {
            list-style-type: lower-roman;
          }
        }
      }
    }
  }
`;

const Terms: React.FC = () => {
  return (
    <Layout>
      <TermsWrapper>
        <SEO title="Terms of use" />
        <h2 style={{ textAlign: 'center' }}>LOWE&rsquo;S OPEN BUILDER 3D PRODUCT CATALOG</h2>
        <h2 style={{ textAlign: 'center' }}>LICENSE TERMS</h2>
        <p>
          <span>
            This is the license and overall terms and conditions of use for the Lowe&rsquo;s Open
            Builder 3D Product Catalog and Site (&ldquo;License Terms&rdquo;) between yourself or as
            an authorized agent on behalf of you (&ldquo;Licensee&rdquo; or &ldquo;you&rdquo;) and
            Lowe&#39;s Home Centers, LLC (the &ldquo;Licensor,&rdquo; &ldquo;we,&rdquo;
            &ldquo;us&rdquo; or &ldquo;our&rdquo;).
          </span>
        </p>
        <p>
          <span>For purposes of these License Terms, the following definitions shall apply:</span>
        </p>
        <p>
          <span>&ldquo;</span>
          <span>3D Product Catalog</span>
          <span>
            &rdquo; shall mean the combination of all the 3D Images available to be downloaded via
            the Site.{' '}
          </span>
        </p>
        <p>
          <span>&ldquo;</span>
          <span>3D Image(s)</span>
          <span>
            &rdquo; shall mean each individual 3D image available to be downloaded via the Site.
            &nbsp;For purpose of these License Terms, 3D Image(s) will be included in the definition
            of 3D Product Catalog.
          </span>
        </p>
        <p>
          <span>&ldquo;</span>
          <span>Site</span>
          <span>&rdquo; shall mean lowesopenbuilder.com</span>
          <span>,</span>
          <span>
            &nbsp;and any other websites or platforms operated by Lowe&rsquo;s which offers a
            License to the 3D Product Catalog.
          </span>
        </p>
        <p>
          <span>&ldquo;</span>
          <span>Metaverse Project</span>
          <span>
            &rdquo; means your production, program, or Metaverse Project in which you upload and/or
            display all or part of the 3D Product Catalog that you license under these License
            Terms.
          </span>
        </p>
        <p>
          <span>&ldquo;</span>
          <span>License</span>
          <span>&rdquo; means the limited licensing rights granted under these License Terms.</span>
        </p>
        <p>
          <span>
            By downloading or using the 3D Product Catalog you accept the License set forth in these
            License Terms.
          </span>
        </p>
        <ol>
          <li>
            <span>License Terms</span>
          </li>
          <ol>
            <li>
              <span>Definition</span>
              <span>:</span>
              <span>
                &nbsp; You shall have the non-exclusive, limited, revocable, non-sub licensable
                right to use the 3D Product Catalog, subject to the terms contained in these License
                Terms. &nbsp;The license is for personal and non-commercial use.
              </span>
            </li>
            <li>
              <span>Term of Use</span>
              <span>:</span>
              <span>
                &nbsp; Your License will be in perpetuity provided you are in compliance with these
                License Terms.
              </span>
            </li>
            <li>
              <span>Territory of Use</span>
              <span>:</span>
              <span>&nbsp; Worldwide.</span>
            </li>
            <li>
              <span>Permissible Media</span>
              <span>: </span>
              <span>
                &nbsp;Any and all media now known or hereafter developed, but intended to be used on
                Metaverse platforms, and subject to the Unpermitted Uses set forth in Section II(B).
              </span>
            </li>
            <li>
              <span>Rights Not Transferable to Third Parties</span>
              <span>:</span>
              <span>&nbsp; You may </span>
              <span>not</span>
              <span>&nbsp;transfer your License to third parties. &nbsp;</span>
            </li>
            <li>
              <span>Sharing and Storage Restrictions</span>
              <span>: &nbsp;</span>
              <span>
                We reserve the right to restrict the number of downloads per licensee, in our
                discretion.
              </span>
            </li>
          </ol>
          <li>
            <span>General License Terms Applicable to All Licenses</span>
          </li>
          <ol>
            <li>
              <span>Permitted Uses</span>
              <span>.</span>
              <span>
                &nbsp; Your License gives you rights to use the 3D Product Catalog in your Metaverse
                Project, in accordance with these Terms. &nbsp;You can edit the 3D Images, provided
                that any such edit (i) shall not alter the fundamental character of the portion of
                the 3D Images being used, and (ii) shall not give rise to any ownership rights or
                claims, including copyright, on your part in or to the resultant edited 3D Images.
              </span>
            </li>
            <li>
              <span>Unpermitted Uses</span>
              <span>.</span>
              <span>
                &nbsp; You may not use the 3D Product Catalog therefrom for the following purposes:
              </span>
            </li>
            <ol>
              <li>
                <span>You may not claim ownership or authorship thereof.</span>
              </li>
              <li>
                <span>
                  You may not use the 3D Product Catalog to compete with us or any of our affiliates
                  in any manner in our sole discretion.
                </span>
              </li>
              <li>
                <span>
                  You may not make the 3D Product Catalog or Metaverse Project available in a way
                  that is intended to allow or invite a third party to download, extract,
                  redistribute or access the 3D Product Catalog as a stand-alone file or on a
                  stand-alone basis.
                </span>
              </li>
              <li>
                <span>
                  You may not license, sell, gift, lend or otherwise distribute copies of the 3D
                  Product Catalog to third parties, other than as part of your Metaverse Project.
                </span>
              </li>
              <li>
                <span>
                  You may not make a stand-alone use of the 3D Product Catalog, it is only to be
                  used in your Metaverse Project.
                </span>
              </li>
              <li>
                <span>
                  You may not use the 3D Product Catalog to manufacture, distribute, advertise,
                  commercialize, promote or sell any product in any format or media now known or
                  hereafter devised.{' '}
                </span>
              </li>
              <li>
                <span>
                  You may not use the 3D Product Catalog for any unlawful purpose, or in violation
                  of applicable regulations or industry codes, including without limitation in
                  violation of any export laws, restrictions or regulations, by shipping,
                  transferring or exporting 3D Product Catalog into any country in violation of any
                  export laws, restrictions or regulation.
                </span>
              </li>
              <li>
                <span>
                  You may not use the 3D Product Catalog in connection with other content that: is
                  pornographic, defamatory, libelous, obscene, immoral, illegal, promotes hate or
                  that otherwise violates any right(s) of any third party(ies).
                </span>
              </li>
              <li>
                <span>
                  You may not attribute a false representation of authorship in the 3D Product
                  Catalog.
                </span>
              </li>
              <li>
                <span>
                  You may not use the 3D Product Catalog as a trademark or service mark. &nbsp;You
                  shall not be entitled to register (in any jurisdiction) such 3D Product Catalog
                  (in whole or in part) as a trademark or rely on any such registrations, prior use,
                  and/or accrued goodwill to prevent any third party use of the 3D Product Catalog
                  or any similar content (including by us, our customers, or the copyright owner of
                  such 3D Product Catalog).
                </span>
              </li>
              <li>
                <span>
                  You may not make derivative works of the 3D Product Catalog, other than as set
                  forth herein.
                </span>
              </li>
              <li>
                <span>
                  You may not use any 3D Product Catalog in connection with an immutable digital
                  asset intended for sale or other distribution (such as a non-fungible token).
                </span>
              </li>
            </ol>
            <li>
              <span>Transferability of Licensed 3D Product Catalog</span>
              <span>. </span>
              <span>
                &nbsp;The rights granted to you hereunder are non-transferable and non-sublicensable
                except in the following situations:
              </span>
            </li>
            <ol>
              <li>
                <span>Employer/Client</span>
                <span>
                  . &nbsp;If you are downloading on behalf of your employer or client, then your
                  employer or client can use the 3D Product Catalog. &nbsp;In that case, you
                  represent and warrant that you have full legal authority to bind your employer or
                  client to the terms of these License Terms. &nbsp;If you do not have that
                  authority, then your employer or client may not use the 3D Product Catalog without
                  obtaining their own License. &nbsp;The rights purchased may only belong to you or
                  your employer/client, depending on who is named as the &ldquo;Licensee&rdquo; at
                  the time of download.{' '}
                </span>
              </li>
              <li>
                <span>Subcontractors</span>
                <span>
                  . &nbsp;You may allow subcontractors or distributors to use 3D Product Catalog in
                  any production or distribution process related to your final Metaverse Project or
                  end use. &nbsp;These subcontractors and distributors must agree to be bound by
                  these License Terms and may not use the 3D Product Catalog for any other purpose.
                </span>
              </li>
            </ol>
            <li>
              <span>Monitoring</span>
              <span>.</span>
              <span>
                &nbsp; We reserve the right to monitor downloads and user activity to ensure
                compliance with these License Terms. &nbsp;If we determine that you are in breach of
                these License Terms in any way, we may suspend access to the 3D Product Catalog and
                seek further legal or equitable remedies.
              </span>
            </li>
            <li>
              <span>Ownership Rights, Attribution and Responsibilities</span>
              <span>.</span>
            </li>
            <ol>
              <li>
                <span>Ownership</span>
                <span>
                  . &nbsp;The 3D Product Catalog, both before and after it is licensed to you, is
                  owned by Lowe&rsquo;s. &nbsp;Any and all rights not expressly granted to you in
                  these License Terms are expressly reserved by us.{' '}
                </span>
              </li>
              <li>
                <span>Use of IP Rights</span>
                <span>
                  . &nbsp;Without our express written approval, you may not use Lowe’s or any Lowe’s
                  affiliate name, or any Lowe’s or any Lowe’s affiliate intellectual property or
                  other proprietary rights (collectively &ldquo;IP Rights&rdquo;), or the name or IP
                  Rights, other than as necessary to give attribution/credit as to the source of the
                  3D Product Catalog.
                </span>
              </li>
            </ol>
            <li>
              <span>Term and Termination</span>
              <span>.</span>
            </li>
            <ol>
              <li>
                <span>Term</span>
                <span>. &nbsp;The Term of your License is as set forth herein.</span>
              </li>
              <li>
                <span>Termination/Cancellation/Withdrawal</span>
                <span>.</span>
              </li>
              <ol>
                <li>
                  <span>Termination by Us</span>
                  <span>
                    . &nbsp;We may terminate your License at any time if you breach any of these
                    License Terms. &nbsp;
                  </span>
                </li>
                <ol>
                  <li>
                    <span>
                      In the event we terminate a License, you must immediately cease using the 3D
                      Product Catalog (and any 3D Images relating thereto), as well as take down,
                      destroy and delete all copies of the 3D Image(s) and 3D Product Catalog in
                      your possession or in possession of subcontractors, distributors or third
                      parties.
                    </span>
                  </li>
                  <li>
                    <span>
                      Upon request, you must confirm in writing that you have complied with these
                      requirements.
                    </span>
                  </li>
                </ol>
                <li>
                  <span>3D Product Catalog or Related 3D Images Removed from Site</span>
                  <span>
                    . &nbsp;From time to time, the 3D Product Catalog or 3D Images therefrom may be
                    discontinued and may no longer be available in the 3D Product Catalog for any
                    number of reasons. &nbsp;
                  </span>
                </li>
              </ol>
            </ol>

            <li>
              <span>
                THE 3D PRODUCT CATALOG IS PROVIDED &ldquo;AS IS&rdquo; WITHOUT REPRESENTATION,
                WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                LIMITED TO, IMPLIED REPRESENTATIONS, WARRANTIES OR CONDITIONS OF MERCHANTABILITY, OR
                FITNESS FOR A PARTICULAR PURPOSE.
              </span>
            </li>
            <li>
              <span>Indemnification/Limitation of Liability</span>
              <span>.</span>
            </li>
            <ol>
              <li>
                <span>Your Indemnification of Us</span>
                <span>
                  . &nbsp;You agree to defend, indemnify and hold harmless us, our parent,
                  subsidiaries, affiliates, advertising agencies or content suppliers, and each of
                  their respective officers, directors and employees from any and all Losses arising
                  out of, or in connection with, (i) your use of the 3D Product Catalog outside the
                  scope of these License Terms; (ii) any breach or alleged breach by you (or anyone
                  acting on your behalf) of any of these License Terms; and (iii) for any
                  modifications you make to any 3D Image or the 3D Product Catalog, even if
                  permitted under these License Terms.
                </span>
              </li>
              <li>
                <span>Requirements</span>
                <span>
                  . &nbsp;We shall promptly notify you in writing of any claim for which we are
                  seeking indemnification, and you shall have the right to assume the handling,
                  settlement or defense of any claim or litigation to which indemnification applies,
                  provided we shall have the right to participate in our defense at our own expense.
                </span>
              </li>
              <li>
                <span>Limitation of Liability</span>
                <span>
                  . &nbsp;LOWE&rsquo;S WILL NOT BE LIABLE TO YOU OR ANY OTHER PERSON OR ENTITY FOR
                  ANY LOST PROFITS, PUNITIVE, SPECIAL, INDIRECT, CONSEQUENTIAL, INCIDENTAL OR OTHER
                  SIMILAR DAMAGES, COSTS OR LOSSES ARISING OUT OF THESE TERMS OR ANY LICENSE, EVEN
                  IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, COSTS OR LOSSES.
                  &nbsp;SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OR LIMITATION OF IMPLIED
                  WARRANTIES OR LIABILITY. &nbsp;IN NO EVENT SHALL OUR LIABILITY FOR A CLAIM ARISING
                  OUT OF THESE TERMS, LICENSE, THE 3D PRODUCT CATALOG OR ANY OTHER CAUSE HEREUNDER,
                  WHETHER UNDER ANY THEORY OF LAW, INCLUDING CONTRACT OR TORT, EXCEED THE FEE PAID
                  FOR THAT LICENSE.
                </span>
              </li>
              <li>
                <span>
                  If you are a California resident, you hereby waive California Civil Code
                  &sect;1542, which says: &ldquo;A general release does not extend to claims that
                  the creditor or releasing party does not know or suspect to exist in his or her
                  favor at the time of executing the release and that, if known by him or her, would
                  have materially affected his or her settlement with the debtor or released
                  party.&rdquo; &nbsp;This release includes the criminal acts of others.
                </span>
              </li>
            </ol>

            <li>
              <span>Miscellaneous Terms</span>
              <span>.</span>
            </li>
            <ol>
              <li>
                <span>Assignment</span>
                <span>
                  . &nbsp;These License Terms are personal to you and are not assignable by you
                  without our prior written consent. &nbsp;We may assign these License Terms,
                  without notice or consent, to any corporate affiliate or to any successor in
                  interest.
                </span>
              </li>
              <li>
                <span>Audit</span>
                <span>
                  . &nbsp;We may audit your use of the 3D Product Catalog upon reasonable advance
                  written notice to you to confirm compliance with the terms of your License.
                  &nbsp;Upon reasonable written request, you agree to provide us with samples of, or
                  access to, websites or platforms where use of the 3D Product Catalog is being made
                  by you. &nbsp;In addition, upon reasonable notice, we may through our own
                  employees or through a third party, audit your records directly related to the 3D
                  Product Catalog in order to verify compliance with these License Terms.{' '}
                </span>
              </li>
              <li>
                <span>Electronic storage</span>
                <span>
                  . &nbsp;You agree to retain the copyright symbol, our name, the identification
                  number and any other information or metadata that may be embedded in the
                  electronic file containing the 3D Images in the original 3D Product Catalog, and
                  to maintain appropriate security to protect the 3D Product Catalog from
                  unauthorized use by third parties. &nbsp;You may make one (1) copy of the 3D
                  Product Catalog for back-up purposes.
                </span>
              </li>
            </ol>
          </ol>
          <li>
            <span>GENERAL TERMS AND CONDITIONS</span>
          </li>
          <ol>
            <li>
              <span>Use of the 3D Product Catalog</span>
              <span>. &nbsp;</span>
              <span>
                We may, in our sole and absolute discretion, terminate, suspend or otherwise deny
                your access to all or part of the 3D Product Catalog at any time, for any or no
                reason at all, with or without notice to you. &nbsp;If we terminate your right to
                access the 3D Product Catalog, these License Terms will terminate and all rights you
                have to access the 3D Product Catalog will immediately terminate. &nbsp;Certain
                provisions of these License Terms will still apply after termination of such rights,
                including, without limitation, and as applicable, the Disputes/Binding Individual
                Arbitration provision.
              </span>
              <p>
                <span>
                  As a condition of your use of the 3D Product Catalog, you represent and warrant to
                  us that you will not use the 3D Product Catalog for any purpose that is unlawful
                  or prohibited by these License Terms or our policies as posted on the 3D Product
                  Catalog or as given to you in writing.
                </span>
              </p>
              <p>
                <span>
                  You may not use the 3D Product Catalog in any manner that could damage, disable,
                  overburden, or impair our server, or the network(s) connected to our server, or
                  interfere with any other party&#39;s use and enjoyment of the 3D Product Catalog.
                  &nbsp;You may not attempt to gain unauthorized access to the 3D Product Catalog,
                  computer systems or networks connected to any of our servers or to the 3D Product
                  Catalog, through hacking, password mining or any other means. &nbsp;You may not
                  obtain, or attempt to obtain, any materials or information through any means not
                  intentionally made available through the 3D Product Catalog. &nbsp;You may not use
                  the 3D Product Catalog in a way that infringes rights of any third parties,
                  including willfully harming a person or entity, including us, or any of our
                  affiliates. &nbsp;You may not commercially distribute, publish, license, or sell
                  any products, information or services obtained from the 3D Product Catalog.
                  &nbsp;THE COPYING OR REPRODUCTION OF THE 3D PRODUCT CATALOG SOFTWARE TO ANY OTHER
                  SERVER OR LOCATION FOR FURTHER REPRODUCTION OR REDISTRIBUTION IS EXPRESSLY
                  PROHIBITED.
                </span>
              </p>
              <p>
                <span>
                  We may at any time (i) modify or discontinue any part of the 3D Product Catalog;
                  or (ii) modify or introduce different opportunities to some or all the 3D Product
                  Catalog users.
                </span>
              </p>
            </li>
            <li>
              <span>3D Product Catalog Intellectual Property</span>
              <span>.</span>
              <span>
                &nbsp; The 3D Product Catalog is owned and operated by us and contains materials
                (including all software, design, text, editorial materials, informational text,
                photographs, illustrations, audio clips, artwork and other graphic materials, and
                names, logos, trademarks and service marks) that are derived in whole or in part
                from materials supplied by us/or one of our affiliates and are protected by
                copyright, trademark and other intellectual property laws. &nbsp;All intellectual
                property rights subsisting in the 3D Product Catalog, including all software, data,
                and content subsisting in or used in connection with the 3D Product Catalog, and
                access to content and hardware used in connection with the 3D Product Catalog
                (collectively defined as &ldquo;Property&rdquo;), belong to us/or one of our
                affiliates and you shall have the right to use any of our Property without an
                express License.
              </span>
            </li>
            <li>
              <span>Links to Third-Party Sites</span>
              <span>.</span>
              <span>
                &nbsp; We may include links to third-party websites that let you leave the 3D
                Product Catalog. &nbsp;These linked sites are not under our control, and we are not
                responsible for the contents of any linked site or any link contained in a linked
                site. &nbsp;We are providing these links to you only as a convenience, and the
                inclusion of any link does not imply endorsement by us. &nbsp;Your use of the
                third-party website may be subject to that third party&rsquo;s terms and conditions.
                &nbsp;YOUR USE OF THIRD PARTY WEBSITES AND RESOURCES IS AT YOUR OWN RISK.
              </span>
            </li>
            <li>
              <span>3D Product Catalog Disclaimers of Warranties and Limitations of Liability</span>
              <span>. </span>
              <span>
                &nbsp;No warranty is given about the quality, functionality, availability or
                performance of the 3D Product Catalog. &nbsp;Except where prohibited by law, the 3D
                Product Catalog is provided &ldquo;AS IS&rdquo; and &ldquo;AS AVAILABLE&rdquo; with
                all faults. &nbsp;OTHER THAN AS REQUIRED BY LAW, WE MAKE NO REPRESENTATIONS OR
                WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE 3D PRODUCT
                CATALOG, (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE
                AVAILABLE TO YOU THROUGH THE SITE OR PRODUCT CATALOG, UNLESS OTHERWISE SPECIFIED IN
                WRITING. &nbsp;SPECIFICALLY, BUT WITHOUT LIMITATION, WE DO NOT WARRANT THAT (I) THE
                INFORMATION AVAILABLE ON THE SITE AND/OR 3D PRODUCT CATALOG IS FREE OF ERRORS; (II)
                THE FUNCTIONS OR FEATURES (INCLUDING, BUT NOT LIMITED TO, MECHANISMS FOR THE
                DOWNLOADING AND UPLOADING OF CONTENT) WILL BE UNINTERRUPTED, SECURE OR FREE OF
                ERRORS; (III) DEFECTS WILL BE CORRECTED; OR (IV) THE SITE AND/OR 3D PRODUCT CATALOG
                OR THE SERVERS THAT MAKE THE SITE AND/OR 3D PRODUCT CATALOG AVAILABLE ARE FREE OF
                VIRUSES OR OTHER HARMFUL COMPONENTS. &nbsp;YOU EXPRESSLY AGREE THAT YOUR USE OF THE
                SITE AND/OR 3D PRODUCT CATALOG IS AT YOUR SOLE RISK. &nbsp;SOME STATES DO NOT ALLOW
                LIMITATIONS ON IMPLIED WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
              </span>
              <p>
                <span>
                  In addition to the limitations of liability of these Terms and Conditions, We
                  expressly disclaim any implied warranty of merchantability, warranty of fitness
                  for a particular purpose and warranty of non-infringement. &nbsp;We assume no
                  liability for any inability to purchase, access, download or use any content, data
                  or service in connection with the Site and/or 3D Product Catalog. &nbsp;WE
                  EXCLUDE, AND SHALL NOT BE LIABLE FOR, ANY LOSS OR DAMAGE SUFFERED BY YOU OR ANY
                  THIRD PARTY, WHETHER DIRECT, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL AND
                  HOWEVER ARISING, AS A RESULT OF USING, ACCESSING, OR PURCHASING SERVICES AND/OR
                  PRODUCTS FROM THE SITE AND/OR 3D PRODUCT CATALOG. &nbsp;UNLESS THIS PROVISION IS
                  UNENFORCEABLE IN YOUR JURISDICTION, THE FOREGOING LIMITATIONS, EXCLUSIONS AND
                  DISCLAIMERS APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EVEN IF ANY
                  REMEDY FAILS OUR ESSENTIAL PURPOSE. &nbsp;SOME STATES DO NOT ALLOW THE EXCLUSION
                  OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR
                  EXCLUSION MAY NOT APPLY TO YOU.
                </span>
              </p>
              <p>
                <span>
                  As a condition of the use of the 3D Product Catalog, you agree to defend,
                  indemnify and hold us harmless from and against any liabilities, losses,
                  investigations, inquiries, claims, suits, damages, costs and expenses (including,
                  without limitation, reasonable attorneys&rsquo; fees and expenses) (each, a
                  &ldquo;Claim&rdquo;) arising out of or otherwise relating to Claims alleging facts
                  that if true would constitute a breach by you of these Terms and Conditions, or
                  other unauthorized use of the 3D Product Catalog, as set forth herein.
                </span>
              </p>
              <p>
                <span>
                  YOU ACKNOWLEDGE THAT YOU HAVE CAREFULLY READ THIS &ldquo;DISCLAIMER OF
                  WARRANTIES/LIMITATION OF LIABILITY/INDEMNIFICATION&rdquo; LANGUAGE AND FULLY
                  UNDERSTAND THAT IT IS A RELEASE OF LIABILITY. &nbsp;YOU EXPRESSLY AGREE TO
                  UNCONDITIONALLY RELEASE AND DISCHARGE US FROM ANY AND ALL CLAIMS OR CAUSES OF
                  ACTION AND YOU AGREE TO VOLUNTARILY GIVE UP AND IRREVOCABLY WAIVE AND RELEASE ANY
                  RIGHT THAT YOU MAY OTHERWISE HAVE TO BRING A LEGAL ACTION AGAINST ANY INDEMNIFIED
                  PARTY INCLUDING, BUT NOT LIMITED TO FOR PERSONAL INJURY, PROPERTY DAMAGE, DEATH,
                  TEMPORARY OR PERMANENT DISABILITY, LOSS OF SERVICES, LOSS OF CONSORTIUM OR LOSS OF
                  PRIVACY.
                </span>
              </p>
            </li>
            <li>
              <span>Governing Law and Jurisdiction</span>
              <span>. &nbsp;</span>
              <span>
                You agree that these License Terms and your use of the Site and/or 3D Product
                Catalog will be governed by the laws of the state of North Carolina. &nbsp;By using
                the Site and/or 3D Product Catalog, you consent to the exclusive jurisdiction and
                venue of the courts, tribunals, agencies and other dispute resolution organizations
                in Charlotte, North Carolina, in all disputes (i) arising out of, relating to or
                concerning these License Terms, the Site and/or 3D Product Catalog and your use
                thereof; (ii) in which the Site and/or 3D Product Catalog and/or these License Terms
                is an issue or a material fact; or (iii) in which the Site and/or 3D Product Catalog
                or these License Terms is referenced in a paper filed in a court, tribunal, agency
                or other dispute resolution organization.&#8239;
              </span>
            </li>
            <li>
              <span>Disputes/Binding Individual Arbitration</span>
              <span>.</span>
            </li>
            <ol>
              <li>
                <span>
                  Your use of the Site and/or 3D Product Catalog is unauthorized in any jurisdiction
                  that does not give full effect to all provisions of the License Terms, and you
                  agree not to access the 3D Product Catalog in any jurisdiction where all or any
                  portion of the Site and/or 3D Product Catalog violates any legal requirements.
                  &nbsp;You are responsible for complying with all applicable laws. &nbsp;Any use in
                  contravention of any provision of these License Terms is at your own risk.&#8239;{' '}
                </span>
              </li>
              <li>
                <span>
                  Lowe&rsquo;s and You each recognize and agree that good faith, informal efforts to
                  resolve disputes often result in prompt, low-cost and mutually beneficial
                  outcomes. &nbsp;Therefore, You and Lowe&rsquo;s each agree that, before either of
                  us may commence an arbitration or assert a claim in small claims court, You and
                  Lowe&rsquo;s will engage in the following informal dispute resolution process:
                  &nbsp;
                </span>
              </li>
              <ol>
                <li>
                  <span>
                    You must first present any claim or dispute to Lowe&rsquo;s by contacting the
                    customer care center to allow Lowe&rsquo;s the opportunity to resolve any
                    dispute. &nbsp;To initiate informal dispute resolution, You must provide
                    Lowe&rsquo;s your full name and contact information; a description of your
                    claim; information about the specific purchase, or matter at issue; your
                    counsel&rsquo;s name and contact information, if applicable; and a description
                    of the relief sought. &nbsp;
                  </span>
                </li>
                <li>
                  <span>
                    You and Lowe&rsquo;s will personally meet and confer, via teleconference or
                    videoconference, in a good faith effort to informally resolve any claim covered
                    by this provision. &nbsp;If either You or Lowe&rsquo;s is represented by
                    counsel, that counsel may, but is not required to, participate in the informal
                    dispute resolution conference. &nbsp;
                  </span>
                </li>
                <li>
                  <span>
                    Either party may request arbitration or a proceeding in small claims court, as
                    applicable, if your dispute cannot be resolved within sixty (60) days, unless an
                    extension is mutually agreed upon by us. &nbsp;
                  </span>
                </li>
                <li>
                  <span>
                    Any statute of limitations will be tolled while You and Lowe&rsquo;s engage in
                    the informal dispute resolution process described in this section.
                  </span>
                </li>
                <li>
                  <span>
                    YOU AGREE TO WAIVE, TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, ANY CLAIMS
                    FOR PUNITIVE OR EXEMPLARY DAMAGES AND ANY RIGHT TO PURSUE CLAIMS ON A CLASS OR
                    CONSOLIDATED BASIS OR IN A REPRESENTATIVE CAPACITY, WHICH RELATE IN ANY WAY TO
                    THE SITE AND/OR 3D PRODUCT CATALOG OR THESE LICENSE TERMS.&#8239;{' '}
                  </span>
                </li>
                <li>
                  <span>
                    LOWE&rsquo;S AND YOU UNCONDITIONALLY WAIVE ANY RIGHT TO TRIAL BY JURY IN ANY
                    ACTION, PROCEEDING, COUNTERCLAIM, CROSS CLAIM OR THIRD-PARTY CLAIM ARISING OUT
                    OF OR RELATING TO ANY DISPUTE ARISING UNDER THESE LICENSE TERMS. &nbsp;IF FOR
                    ANY REASON A CLAIM PROCEEDS IN COURT RATHER THAN THROUGH ARBITRATION,
                    LOWE&rsquo;S AND YOU AGREE THAT THERE WILL NOT BE A JURY TRIAL. &nbsp;YOU
                    UNDERSTAND THAT BY THESE LICENSE TERMS YOU ARE WAIVING THE RIGHT TO HAVE ANY
                    DISPUTE HEARD AND RESOLVED IN COURT BY A JURY. &nbsp;YOU AGREE TO WAIVE, TO THE
                    FULLEST EXTENT ALLOWED BY APPLICABLE LAW, ANY CLAIMS FOR PUNITIVE OR EXEMPLARY
                    DAMAGES AND ANY RIGHT TO PURSUE CLAIMS ON A CLASS OR CONSOLIDATED BASIS OR IN A
                    REPRESENTATIVE CAPACITY, WHICH RELATE IN ANY WAY TO THE SITE AND/OR THE 3D
                    PRODUCT CATALOG OR THESE LICENSE TERMS.&#8239;{' '}
                  </span>
                </li>
                <li>
                  <span>
                    LOWE&rsquo;S AND YOU UNCONDITIONALLY WAIVE ANY RIGHT TO TRIAL BY JURY IN ANY
                    ACTION, PROCEEDING, COUNTERCLAIM, CROSS CLAIM OR THIRD-PARTY CLAIM ARISING OUT
                    OF OR RELATING TO ANY DISPUTE ARISING UNDER THE SITE, THE 3D PRODUCT CATALOG
                    AND/OR THESE LICENSE TERMS. &nbsp;IF FOR ANY REASON A CLAIM PROCEEDS IN COURT
                    RATHER THAN THROUGH ARBITRATION, LOWE&rsquo;S AND YOU AGREE THAT THERE WILL NOT
                    BE A JURY TRIAL. YOU UNDERSTAND THAT BY THESE LICENSE TERMS YOU ARE WAIVING THE
                    RIGHT TO HAVE ANY DISPUTE HEARD AND RESOLVED IN COURT BY A JURY. &nbsp;
                  </span>
                </li>
              </ol>
              <li>
                <span>Arbitration:</span>
                <span>
                  &nbsp; Please read this section carefully because it affects rights that you might
                  otherwise have. &nbsp;It provides for resolution of most disputes through
                  arbitration instead of court proceedings.&#8239;{' '}
                </span>
              </li>
              <ol>
                <li>
                  <span>
                    Arbitration is different than court. &nbsp;There is no judge or jury, but the
                    arbitrator can award damages. &nbsp;Arbitration is final, binding and subject to
                    only limited review by a court. &nbsp;By using the Site and/or 3D Product
                    Catalog, you agree to give up your right to a trial in court except for certain
                    small claims as described below.&#8239;{' '}
                  </span>
                </li>
                <li>
                  <span>
                    You agree that this arbitration section shall survive termination of these
                    License Terms. &nbsp;This section shall be broadly interpreted to encompass all
                    disputes or claims arising out of or relating to your use of the Site and/or the
                    3D Product Catalog. &nbsp;Any dispute or claim made by you against Lowe&rsquo;s
                    or against any of Lowe&rsquo;s subsidiary, parent or affiliated companies
                    arising out of or related to your use of the Site, and/or the 3D Product Catalog
                    whether based on contract, statute, tort, fraud, misrepresentation or any other
                    legal theory, will be resolved by binding arbitration, except that either party
                    may elect to have such claims heard in small claims court if they qualify for
                    hearing by such court.&#8239;{' '}
                  </span>
                </li>
                <li>
                  <span>
                    You must first present any claim or dispute to Lowe&rsquo;s by contacting the
                    customer care center to allow Lowe&rsquo;s the opportunity to resolve any
                    dispute, as outlined more fully above. &nbsp;Either party may request
                    arbitration or a proceeding in small claims court, as applicable, if your
                    dispute cannot be resolved within sixty (60) days, unless an extension is
                    mutually agreed upon by us. &nbsp;The arbitration of any dispute or claim shall
                    be conducted with the rules of a Nationwide Arbitration Organization as modified
                    by these License Terms, and there will be NO JURY TRIAL. &nbsp;Any arbitration
                    demand must be signed by the claimant and must include a description of the
                    claim; information about the specific purchase, transaction or matter at issue;
                    and a description of relief sought. &nbsp;
                  </span>
                </li>
                <li>
                  <span>
                    You and Lowe&rsquo;s agree that these License Terms evidence a transaction in
                    interstate commerce and this arbitration provision will be interpreted and
                    enforced in accordance with the US Federal Arbitration Act and federal
                    arbitration law. &nbsp;Any such arbitration shall be brought and held in
                    Charlotte, North Carolina, and will be conducted in the English language.&#8239;
                    An arbitrator may not (i) award relief in excess of or contrary to what these
                    License Terms provides; (ii) order consolidation or arbitration on
                    a&#8239;class-wide&#8239;or representative basis; or (iii) award punitive
                    damages or any other damages aside from the prevailing party&rsquo;s actual
                    damages, except that (a) the arbitrator may award on an individual basis damages
                    required by statute and may order injunctive or declaratory relief pursuant to
                    an applicable consumer protection statute, and (b) in the event the claim
                    brought is frivolous, unreasonable or without foundation, or claimant continues
                    to prosecute a claim after the claim becomes frivolous, unreasonable or without
                    foundation, claimant will be required to reimburse respondent for its costs,
                    expenses and reasonable attorneys&rsquo; fees, amounts paid in the
                    investigation, defense and/or settlement of such claims, and all other amounts
                    allowed by law. &nbsp;In any arbitration applying those rules applicable to
                    large and/or complex cases, the arbitrators may also apply the Federal Rules of
                    Evidence, and the losing party may have the award reviewed in accordance with
                    the review procedures set forth in the selected arbitrator&rsquo;s rules.&#8239;{' '}
                  </span>
                </li>
                <li>
                  <span>
                    Any arbitration shall be confidential, and neither you nor Lowe&rsquo;s may
                    disclose the existence, content or results of any arbitration, except as may be
                    required by law or for purposes of enforcement or appeal of the arbitration
                    award. &nbsp;The decisions of the arbitrators shall be binding and conclusive
                    upon all parties involved, and any judgment or decision on any arbitration may
                    be entered&#8239;in,&#8239;and specifically enforced in, any court having proper
                    jurisdiction.&#8239;{' '}
                  </span>
                </li>
                <li>
                  <span>
                    If any portion of this section is determined by a court to be inapplicable or
                    invalid, then the remainder shall be given full force and effect.&#8239;{' '}
                  </span>
                </li>
                <li>
                  <span>
                    Claimant will be required to pay any applicable filing fees. &nbsp;All
                    administrative fees and expenses of arbitration will be divided equally between
                    you and Lowe&rsquo;s. &nbsp;Unless an applicable statute expressly permits
                    awarding attorneys&rsquo; fees to the prevailing party, each party will bear the
                    expense of its own counsel, experts, witnesses, and preparation and presentation
                    of evidence at any arbitration hearing. &nbsp;&#8239;However, as noted above, in
                    the event the claim brought is frivolous, unreasonable or without foundation, or
                    claimant continues to prosecute a claim after the claim becomes frivolous,
                    unreasonable or without foundation, claimant will be required to reimburse
                    respondent for its costs, expenses and reasonable attorneys&rsquo; fees, amounts
                    paid in the investigation, defense and/or settlement of such claims, and all
                    other amounts allowed by law. &nbsp;
                  </span>
                </li>
              </ol>
            </ol>

            <li>
              <span>Severability</span>
              <span>
                . &nbsp;If any clause within this section (other than the class action waiver clause
                above) is found to be illegal or unenforceable, that clause will be severed from
                this section, and the remainder of this section will be given full force and effect.
                &nbsp;If the class action waiver clause is found to be illegal or unenforceable, the
                entire section will be unenforceable, and the dispute will be decided by a court and
                you and we each agree to waive in that instance, to the fullest extent allowed by
                law, any trial by jury.
              </span>
            </li>
            <li>
              <span>Continuation</span>
              <span>
                . &nbsp;This section survives any termination of these License Terms or the
                provision of the Site and/or 3D Product Catalog to you.
              </span>
            </li>
            <li>
              <span>General Legal</span>
              <span>
                . &nbsp;These License Terms inure to the benefit of the parties, including any of
                our successors in interest. &nbsp;These License Terms constitute the entire License
                Terms between you and us and supersede any prior License Terms between you and us on
                the subject matter, except as provided herein. &nbsp;These License Terms, and any
                rights or Licenses granted hereunder, may not be assigned or delegated by you.
                &nbsp;These License Terms may only be modified in a writing signed by our authorized
                representative. &nbsp;Under no circumstances shall we be held liable for any delay
                or failure in performance resulting directly or indirectly from an event beyond our
                reasonable control. &nbsp;Should we fail to insist that you perform any obligations
                under these License Terms, or if we do not enforce our rights against you, or if we
                delay in doing so, it will not mean that we have waived such rights and will not
                mean that you do not have to comply with your obligations. &nbsp;If we do waive a
                failure or breach by you, we will only do so in writing and that will not mean that
                we automatically waive any future failure of breach by you. You agree that, except
                as otherwise set forth here, there shall be no third-party beneficiaries to these
                License Terms. &nbsp;No agency, partnership, joint-venture or employee-employer
                relationship is intended or created by these License Terms. &nbsp;You agree that
                these License Terms made by and between you and us in electronic form are as legally
                binding as if made in physical written form. &nbsp;The section titles in these
                License Terms are for convenience only and have no legal or contractual effect.
                &nbsp;Any provision of these License Terms that by its nature is reasonably intended
                to survive beyond termination of these License Terms shall survive. &nbsp;If any
                clause within these License Terms is found to be illegal or unenforceable, that
                clause will be severed from this section, and the remainder of this section will be
                given full force and effect.
              </span>
            </li>
          </ol>
        </ol>
        <p>
          <span>Effective as of: June, 2022</span>
        </p>
      </TermsWrapper>
    </Layout>
  );
};

export default Terms;
